@import "./main.scss";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  background: $background;
  color: $text;
  scroll-behavior: smooth;
  font-family: "Chivo", sans-serif;
  height: 100vh;
  overflow-x: hidden;
  line-height: 1.4;

  @include responsive(normal) {
    overflow-x: unset;
  }
}

html {
  font-size: 10px;
  min-height: 100%;
  height: auto;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: thin;

  // @include responsive(small) {
  //   font-size: 56% !important;
  // }

  // @include responsive(mac-13) {
  //   font-size: 62.5% !important;
  // }
}

body {
  width: 100%;
  // overflow: hidden;
  font-weight: 400;
  font-size: 2.4rem;
}

a {
  color: $text;
  text-decoration: none;
  transition: 0.3s all;
  cursor: pointer;

  &:hover {
    // color: $second;
  }
}

.top-header {
  padding-top: 9.6rem;
}

img {
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
