@import "styles/main.scss";

.foundation {
  background-color: red;

  :global {
    .foundation-container {
      position: relative;
      background-color: $background;

      .foundation-head {
        top: 0;
        background-color: $background;
        z-index: 1;
        padding: 0 $space-6;

        @include responsive(tablet) {
          position: sticky;
        }

        @include responsive(mac-13) {
          position: sticky;
          padding: 0;
        }

        .foundation-head__content {
          @include container;
          display: grid;
          grid-template-columns: 1fr;
          border-bottom: 1px solid $background-child;
          gap: $space-4;
          padding: 12rem 0 $space-6 !important;

          @include responsive(small) {
            grid-template-columns: repeat(2, 1fr);
          }

          @include responsive(mac-16) {
            gap: 0;
            padding: 10rem 0 $space-6 !important;
          }

          .title-head {
            font-weight: normal;
            width: 80%;
          }

          .text-description {
            line-height: 1.4;
          }
        }
      }

      .foundation-body {
        // height: 80vh;
        // overflow: auto;
        background-color: $background;
        margin-top: $space-20;

        .item-content {
          @include container;
          display: grid;
          grid-template-columns: 1fr;
          width: 100%;
          margin-bottom: $space-12;

          @include responsive(small) {
            margin-bottom: 15rem;
          }

          @include responsive(mac-16) {
            height: 60vh;
          }

          &:last-child {
            margin-bottom: 0;
            padding-bottom: $space-20 !important;
          }

          @include responsive(small) {
            grid-template-columns: repeat(2, 1fr);
          }

          @include responsive(mac-13) {
            padding: 0 !important;
          }

          .img-box {
            width: 100%;
            display: flex;
            align-items: center;

            // @include responsive(small) {
            //   justify-content: center;
            // }

            .img-content {
              width: 20rem;

              @include responsive(tablet) {
                width: 40%;
                margin-bottom: $space-6;
              }

              @include responsive(small) {
                width: 60%;
                margin-bottom: 0;
              }

              @include responsive(mac-16) {
                width: 70%;
                margin-bottom: 0;
              }

              // @include responsive(screen2k) {
              //   width: 150%;
              //   transform: translateX(-25rem);
              // }
            }
          }

          .content-text {
            width: 100%;
            height: unset;
            align-items: unset;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .content-text__title {
              margin-bottom: $space-6;
            }

            .content-text__text {
              line-height: 1.4;
              color: $text-secondary;

              span {
                color: $secondary;
              }
            }
          }
        }
      }
    }
  }
}
