@import "styles/main.scss";

.header {
  width: 100%;
  padding: $space-8 0;
  position: fixed;
  transition: 0.3s all;
  z-index: 99999;
  background-color: $background;

  &--show {
    top: 0;
  }

  &--hide {
    top: -100%;
  }

  .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include container;

    .icon-logo {
      width: 16rem;
      cursor: pointer;
    }

    .icon-menu {
      font-size: 3rem;
    }

    .header-menu {
      gap: $space-6;
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 100%;
      left: 100%;
      height: 90vh;
      width: 100%;
      background-color: $background;
      z-index: 999;
      padding: 0 $space-5;
      transition: 0.3s left;
      border-top: 1px solid $background-child;
      padding-top: $space-5;

      &--show {
        left: 0;
      }

      @include responsive(mobile) {
        border-top: none;
        background-color: transparent;
        align-items: center;
        position: unset;
        height: unset;
        flex-direction: row;
        width: unset;
        padding: 0;
      }

      .menu-item {
        cursor: pointer;
        transition: 0.3s all;

        &:hover {
          color: $hover;
        }

        &--dropdown {
          position: relative;
          z-index: 0;
          // cursor: pointer;

          // &:hover {
          // color: unset;
          // }

          @include responsive(small) {
            &:hover {
              .icon-down {
                transform: rotate(180deg);
              }

              .menu-child-content {
                height: 15rem !important;

                @include responsive(tablet-2) {
                  height: 17rem !important;
                }
              }
            }
          }

          &--expanse {
            .icon-down {
              transform: rotateX(180deg);
            }

            .menu-child-content {
              height: 15rem !important;

              @include responsive(tablet-2) {
                height: 17rem !important;
              }
            }
          }

          .text-menu {
            display: flex;
            align-items: center;
            gap: $space-2;
          }

          .icon-down {
            font-size: 2.4rem;
            transition: 0.3s all;
          }

          .menu-child-content {
            width: 100%;
            height: 0;
            overflow: hidden;
            transition: 0.3s height;

            @include responsive(mobile) {
              display: flex;
              align-items: flex-end;
              position: absolute;
              width: 120%;
              left: 0;
              top: 100%;
            }

            .menu-child {
              padding: 1rem 0 0;
              width: 100%;
              height: auto;

              @include responsive(mobile) {
                padding: 0;
                background-color: $background-child;
              }

              .menu-child-item {
                cursor: pointer;
                transition: 0.3s all;
                padding: $space-3 $space-4;
                display: flex;
                align-items: center;
                gap: $space-2;

                &:hover {
                  color: $hover;
                }

                .icon-community {
                  font-size: 2.4rem;
                }
              }
            }
          }
        }

        &--contact {
          @include responsive(mobile) {
            display: none;
          }
        }
      }
    }
  }
}
