@import "styles/main.scss";

.landingBody {
  :global {
    .content-text-star {
      height: 100vh;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      position: sticky;
      top: 0;
      background-color: $background;
      z-index: 0;

      .icon-star {
        font-size: 4rem;
        margin-bottom: $space-12;

        @include responsive(mac-16) {
          transform: translateY(-10rem);
        }

        @include responsive(screen2k) {
          font-size: 6rem;
        }
      }

      .text {
        width: 100%;
        padding: 0 $space-6;
        text-align: center;
        font-weight: normal;

        @include responsive(small) {
          padding: 0;
          width: 60%;
        }

        @include responsive(mac-16) {
          transform: translateY(-10rem);
        }

        @include responsive(screen2k) {
          width: 900px;
        }

        span {
          color: $secondary;
        }
      }

      .bg-grid {
        position: absolute;
        z-index: -1;

        &--grid-1 {
          top: 0;
          left: -10rem;
          width: 30rem;

          @include responsive(small) {
            width: 30vw;
          }
        }

        &--grid-2 {
          bottom: 20rem;
          right: 10rem;
          display: none;

          @include responsive(small) {
            display: block;
          }
        }

        &--grid-3 {
          top: 0;
          right: 0;
          width: 30rem;

          @include responsive(small) {
            width: 30vw;
          }
        }

        &--grid-4 {
          bottom: 15rem;
          left: 10rem;
          display: none;

          @include responsive(small) {
            display: block;
          }
        }

        &--grid-5 {
          top: 5rem;
          left: -15rem;
          width: 30rem;

          @include responsive(small) {
            bottom: 15rem;
            left: 10rem;
            width: 20rem;
            display: none;
          }
        }

        &--grid-6 {
          bottom: 15rem;
          right: 0;
          width: 20rem;

          @include responsive(small) {
            bottom: 15rem;
            left: 10rem;
            width: unset;
          }
        }
      }
    }
  }
}
