$primary: #00e8b4;
$secondary: #0cc098;
$hover: #07ad8c;
$active: #07ad8c;
$disable: #d3fff5;

$background: #ffffff;
$background-child: #e6e6e6;
$background-surface: #a4a4a4;

$text: #151515;
$text-secondary: #454a4a;
