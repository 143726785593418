@import "styles/main.scss";

.council {
  padding-top: 11rem;
  background-color: $background;
  z-index: 1;
  position: relative;

  @include responsive(small) {
    position: sticky;
    top: 0;
    z-index: 0;
    height: 100vh;
  }

  @include responsive(mac-13) {
    height: 100vh;
  }

  @include responsive(mac-16) {
    padding-top: $space-10;
    height: 100vh;
    padding-top: 0;
  }

  :global {
    .council-title {
      text-align: center;
      padding-bottom: $space-52;
      font-weight: normal;
      background-color: $background;
      z-index: 1;
      position: relative;
      line-height: 1;

      @include responsive(small) {
        background-color: transparent;
      }

      @include responsive(mac-13) {
        padding-bottom: $space-12;
        background-color: transparent;
      }

      @include responsive(mac-16) {
        padding: 12rem 0 $space-20;
      }
    }

    .council-content {
      position: relative;
      z-index: 0;

      .list-council {
        width: 100%;
        margin: 0 auto $space-10;
        gap: $space-10;
        padding-bottom: $space-20;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        background-color: $background;
        padding: 0 $space-6;

        @include responsive(tablet) {
          width: 70%;
          margin: 0 auto $space-8;
          padding: 0;
        }

        @include responsive(small) {
          @include container;
          gap: $space-6;
          background-color: transparent;
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          margin-bottom: $space-10;
        }

        @include responsive(screen2k) {
          @include container-fluid;
          margin-bottom: $space-20;
        }

        .item-council {
          width: calc(50% - 2.4rem);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: $space-6;
          z-index: 10;

          @include responsive(small) {
            width: 100%;
          }

          .council-img {
            width: 100%;

            @include responsive(small) {
              width: 80%;
            }

            @include responsive(mac-13) {
              width: 100%;
            }
          }

          .council-name {
            font-weight: bold;

            @include responsive(screen2k) {
              font-size: 3.2rem;
            }
          }
        }
      }

      .bg-grid {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        z-index: 0;

        @include responsive(small) {
          height: 150%;
          z-index: -1;
        }
      }
    }

    .council-description {
      text-align: center;
      width: 100%;
      padding: 0 $space-6;
      position: relative;
      background-color: $background;

      @include responsive(small) {
        font-size: 1.8rem;
        background-color: transparent;
        margin: 0 auto;
        padding: 0;
        width: 50%;
      }

      @include responsive(mac-16) {
        font-size: 2rem;
        width: 40%;
      }

      @include responsive(screen2k) {
        width: 660px;
      }

      span {
        color: $secondary;
      }
    }

    .icon-chat {
      position: absolute;
      right: 0;
      bottom: -30%;
      width: 30%;
      display: none;

      @include responsive(small) {
        display: block;
      }
    }
  }
}
