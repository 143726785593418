@import "styles/main.scss";

.footer {
  width: 100%;
  display: none;

  @include responsive(mobile) {
    display: block;
  }

  :global {
    .footer-wrapper {
      @include container;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 $space-16 !important;

      .logo {
        width: 16rem;
        cursor: pointer;
      }

      .footer-right {
        display: flex;
        align-items: center;
        gap: $space-2;
        cursor: pointer;
        transition: 0.3s all;

        &:hover {
          color: $hover;
        }
      }
    }
  }
}
