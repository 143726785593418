@import "styles/main.scss";

.yourIdeas {
  height: 100vh;
  position: sticky;
  top: 0;
  background-color: $background;
  display: flex;
  align-items: center;
  justify-content: center;

  :global {
    .content-wrapper {
      width: 100%;
      position: relative;
      transform: translateY(0rem);

      @include responsive(mac-16) {
        transform: translateY(-10rem);
      }

      .bg-grid {
        position: absolute;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
      }

      .content-body {
        height: 40rem;
        max-width: 100%;
        padding: 0 $space-5;
        margin: 0 auto;
        position: relative;
        z-index: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        @include responsive(small) {
          align-items: unset;
          justify-content: unset;
          max-width: 1200px;
          padding: 0;
        }

        &::after {
          content: "";
          position: absolute;
          height: 100%;
          width: calc(100% + 4rem);
          left: 0;
          top: 0;
          background: linear-gradient(90deg, rgba(87, 246, 222, 0.24) 0%, rgba(87, 246, 222, 0) 100%);
          z-index: -1;

          @include responsive(small) {
            left: unset;
            width: 85%;
            padding: 0;
            right: 0;
          }
        }

        .img-token {
          height: 100%;
          display: none;

          @include responsive(small) {
            display: block;
          }
        }

        .text-content {
          top: 50%;

          @include responsive(small) {
            position: absolute;
            right: 5%;
            width: 49%;
            transform: translateY(-50%);
          }

          h1 {
            margin-bottom: $space-12;
            font-size: 4.8rem;

            @include responsive(tablet) {
              font-size: 6.4rem;
            }

            @include responsive(small) {
              font-size: 7.2rem;
            }
          }

          .btn-custom {
            width: 100%;

            @include responsive(small) {
              width: max-content;
            }

            .button-content {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: $space-2;
              width: 100%;
            }
          }
        }

        .icon-token {
          position: absolute;
          width: 10rem;

          &--icon-1 {
            top: -5rem;
            right: 0;
            animation: upDown 2.5s linear infinite;
            width: 8rem;

            @include responsive(small) {
              width: 10rem;
            }
          }

          &--icon-2 {
            bottom: -5rem;
            left: 4rem;
            animation: upDown 3s linear infinite;
            display: none;

            @include responsive(tablet) {
              display: block;
            }

            @include responsive(small) {
              left: 40rem;
            }
          }

          &--icon-3 {
            width: 15rem;
            bottom: -10rem;
            left: 0;
            animation: upDown 3.5s linear infinite;

            @include responsive(tablet) {
              left: unset;
              bottom: -10rem;
              right: 0;
            }
          }
        }
      }
    }

    .footer-custom {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: $space-8 0;
    }
  }
}
