@import "styles/main.scss";

.banner {
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  // justify-content: center;
  position: relative;
  z-index: 0;

  :global {
    .text-banner {
      font-weight: normal;
      width: 100%;
      padding: 0 $space-5;
      padding-top: calc(5rem + var(--height-header)) !important;
      text-align: center;

      @include responsive(tablet) {
        padding-top: calc(5rem + var(--height-header)) !important;
        margin: 0 auto;
      }

      @include responsive(tablet-2) {
        padding: 0;
      }

      @include responsive(small) {
        width: 50%;
        width: 90rem;
      }

      @include responsive(screen2k) {
        width: 900px;
      }
    }

    .btn-custom {
      margin-top: $space-20;
    }

    .bg-banner {
      position: absolute;
      width: 100%;
      height: 60vh;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;

      @include responsive(mobile) {
        height: 65%;
      }

      @include responsive(tablet-2) {
        height: 74%;
      }

      @include responsive(small) {
        height: 58%;
      }

      @include responsive(mac-13) {
        height: 53%;
      }

      @include responsive(mac-16) {
        height: 62%;
      }

      @include responsive(screen2k) {
        height: 70vh;
      }
    }
  }
}
