@font-face {
  font-family: "Chivo";
  src: url("../assets/font-family/Chivo-Regular.ttf");
  font-weight: normal;
}

@font-face {
  font-family: "Chivo";
  src: url("../assets/font-family/Chivo-Bold.ttf");
  font-weight: bold;
}
